<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="#"
            class="d-flex align-center"
          >
            <v-row class="text-center">
              <v-col class="d-flex justify-center" cols="12">

                <v-img
                  :src="`${this.logo}`"
                  max-height="80px"
                  alt="logo"
                  contain
                  class="me-3 d-flex justify-center"
                ></v-img>

            </v-col>

            <v-col cols="12" sm="12" v-if="this.logo == ''">
              <h2 class="text-2xl font-weight-semibold">
               {{ imobiliaria }}
            </h2>
            </v-col>
            </v-row>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text v-if="this.vFormIdentificar">
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">
            Cadastro de usuários
          </p>
          <p class="mb-2 text-center">
            Informe seu CPF/CNPJ
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text v-if="this.vFormIdentificar">
          <v-form @submit.stop.prevent="submit">
            <v-text-field
              v-model="cpf_cnpj"
              outlined
              label="CPF/CNPJ"
              placeholder="000.000.000-00"
              hide-details
              class="mb-3"
            ></v-text-field>
            <template v-if="this.showChangeEmail">
              <p class="text-1xl font-weight-semibold text--primary mb-4 mt-3 text-center" style="color: red;">
                <router-link :to="{ name:'changemail' }">Alterar E-mail</router-link>
              </p>
            </template>
            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="identificar()"
            >
              Identificar
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- title -->
        <v-card-text v-if="this.vFormCadastrar">
          <p class="text-1xl font-weight-semibold text--primary mb-2 text-center">
            Cadastro de Usuário
          </p>
          <p class="text-1xl font-weight-semibold text--primary mb-2 text-center" style="color: red !important;">
            Informe o código enviado por e-mail para validarmos seu cadastro e uma senha
          </p>
          <p class="text-1xl font-weight-semibold text--primary mb-2 text-center" style="color: red;">
              Não recebeu um código via E-MAIL? <router-link :to="{ name:'changemail' }">Clique aqui para alterar seu email</router-link>
          </p>
        </v-card-text>

        <!-- 2Fator cadastrar form -->
        <v-card-text v-if="this.vFormCadastrar">
          <v-form @submit.stop.prevent="submit">

            <v-text-field
              v-model="codigo2fator"
              outlined
              label="Codigo enviado por e-mail"
              placeholder="A2A302"
              hide-details
              class="mb-3"
              type="text"
            ></v-text-field>

            <v-text-field
              v-model="senha"
              outlined
              label="senha"
              placeholder="********"
              hide-details
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordVisible = !isPasswordVisible"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="resenha"
              outlined
              label="Repita a senha"
              placeholder="********"
              hide-details
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordVisible = !isPasswordVisible"
              class="mb-3"
            ></v-text-field>


            <v-row class="d-flex justify-center col col-12">
              <v-checkbox
              hide-details
              class="mt-1"
              v-model="terms">
               <template #label>

              </template>
            </v-checkbox>
              <div class="d-flex align-center flex-wrap">
                <span class="me-2">Eu aceito os</span><a target="_blank" href="https://integrasfacil.com.br/portaldocliente/politica-de-privacidade"> termos de politica &amp; privacidade</a>
              </div>
            </v-row>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="cadastrar()"
            >
              Confirmar
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- title -->
        <v-card-text v-if="this.vFormSucess">
          <p class="text-1xl font-weight-semibold text--primary mb-2 text-center">
            <v-icon
              x-large
              color="#1ad639"
              class="mb-2">
              {{ icons.mdiCheckUnderlineCircle }}
            </v-icon>
          </p>

          <p class="text-1xl font-weight-semibold text--primary mb-2 text-center">
            Cadastro realizado com sucesso
          </p>
          <p class="mb-2 text-center">
            Clique <router-link :to="{ name:'pages-login' }"> aqui para fazer o login </router-link>
          </p>
        </v-card-text>

        <!-- Make login  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2" v-if="this.vFormCadastrar || this.vFormIdentificar">
          <span class="me-2">
            Já tem uma conta ?
          </span>
          <router-link :to="{ name:'pages-login' }">
            Faça o login aqui
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <v-snackbar
      v-model="snackbar"
      :timeout="-1"
    >
      {{ this.toastText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/customer.png"
    ></v-img>-->

    <!-- tree
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/customer-2.png"
    ></v-img>-->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiCheckUnderlineCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'

import UsuarioStore from './../../store/Usuario'
import Strings from '@/common/strings'

export default {
  data(){
    return{
      imobiliaria: process.env.VUE_APP_INCORPORATION_NAME,
      logo: process.env.VUE_APP_URL_LOGO,
    }
  },
  setup() {
    const terms = ref(false)
    const isPasswordVisible = ref(false)
    const cpf_cnpj = ref('')
    const senha = ref('')
    const resenha = ref('')
    const vFormIdentificar = ref(true)
    const codigo2fator = ref('')
    const vFormCadastrar = ref(false)
    const snackbar = ref(false)
    const toastText = ref('')
    const vFormSucess = ref(false)
    const showChangeEmail = ref(false)

    return {
      isPasswordVisible,
      cpf_cnpj,
      senha,
      resenha,
      codigo2fator,
      vFormIdentificar,
      vFormCadastrar,
      snackbar,
      toastText,
      vFormSucess,
      terms,
      showChangeEmail,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCheckUnderlineCircle,
      },
    }
  },
  methods:{
    identificar(){

        if(this.cpf_cnpj != ''){
          let cpf_cnpj_without_chars = this.cpf_cnpj.replace(/[^\d]+/g, '')

          if(cpf_cnpj_without_chars == ''){
            this.toastText = 'Informe corretamente o CPF/CNPJ'
            this.snackbar = true
            return
          }

            this.vFormIdentificar = false


            var payload = {
              "id": 0,
              "ImobiliariaId": Strings.imobiliariaId,
              "cpfCnpj": cpf_cnpj_without_chars,
              "senha": this.senha,
              "codigo": "",
              "criado_Em": "2022-04-23T00:00:00.828Z",
              "atualizado_em": "2022-04-23T00:00:00.828Z",
              "codPessoa": "",
              "email": ""
            }

            this.$loading(true)
            UsuarioStore.precadastro(payload).then(() =>{
                this.vFormCadastrar = true
                this.$loading(false)
            }).catch(error => {
              this.vFormIdentificar = true
                this.showChangeEmail = true
                this.toastText = error.response.data
                this.snackbar = true
                this.$loading(false)
            })
        }else{
            this.toastText = 'Informe o CPF/CNPJ'
            this.snackbar = true
        }
    },
    cadastrar(){

        if(this.terms){
            if(this.senha != this.resenha){
                this.toastText = "Senhas não conferem"
                this.snackbar = true
            }else{

              let cpf_cnpj_without_chars = this.cpf_cnpj.replace(/[^\d]+/g, '')
              var payload = {
                "id": 0,
                "ImobiliariaId": Strings.imobiliariaId,
                "cpfCnpj": cpf_cnpj_without_chars,
                "senha": this.senha,
                "codigo": this.codigo2fator,
                "criado_Em": "2022-04-23T00:00:00.828Z",
                "atualizado_em": "2022-04-23T00:00:00.828Z",
                "codPessoa": "",
                "email": ""
              }

              this.$loading(true)
              UsuarioStore.confirmarcadastro(payload).then(() =>{

                  this.toastText = 'Cadastro realizado com sucesso!'
                  this.snackbar = true
                  this.vFormCadastrar = false
                  this.vFormSucess = true
                  this.$loading(false)
              }).catch(error => {
                this.vFormCadastrar = true
                  console.log(error.response)
                  this.toastText = error.response.data
                  this.snackbar = true
                  this.$loading(false)
              })

            }
      }else{
          this.toastText = 'Você precisa aceitar os termos para continuar'
          this.snackbar = true
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
